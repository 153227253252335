import { getApp } from "@firebase/app"
import { getFunctions, httpsCallable } from "firebase/functions";

export default class FunctionsService {
    // cohortType - 'daily' | 'weekly' | 'monthly'
    static getActiveUsersNums = async (cohortType, limit) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getActiveUsersNums');
        return functionCall({ cohort: cohortType, limit })
            .then((result) => {
                return result.data.map(d => {
                    let date = d.date.value.slice(5, 10)
                    if (cohortType === 'daily') {
                        const dd = new Date(d.date.value)
                        dd.setDate(dd.getDate())
                        date = dd.toISOString().slice(5, 10)
                    }
                    return {
                        curr: d.curr ?? 0,
                        last: d.last ?? 0,
                        ret: (d.last ?? 0) === 0 ? 0.00 : (((d.both ?? 0) / d.last) * 100).toFixed(2),
                        premium: d.premium ?? null,
                        date: date,
                        both: d.both ?? 0,
                        dateTime: new Date(d.date.value).getTime()
                    }
                })
            });
    }

    static getLessonsOverview = async ({ courseId, startDate, endDate }) => {
        const functions = getFunctions(getApp());
        const functionCall = httpsCallable(functions, 'analytics_lessons_overview');
        return await functionCall({ courseId: courseId, startDate: startDate, endDate: endDate });
    }


    static setPermission = async ({ type, email }) => {
        const functions = getFunctions(getApp());
        const functionCall = httpsCallable(functions, 'setPermission');
        return await functionCall({ type: type, email: email });
    }

    static resetPermission = async ({ email }) => {
        const functions = getFunctions(getApp());
        const functionCall = httpsCallable(functions, 'resetPermission');
        return await functionCall({ email: email });
    }

    static setPayment = async ({ uid, currentMonth }) => {
        const functions = getFunctions(getApp());
        const functionCall = httpsCallable(functions, 'setPayment');
        return await functionCall({ uid: uid, currentMonth: currentMonth });
    }

    static getPremiumUsersNums = async (cohortType, limit) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getPremiumUsersNums');
        return functionCall({ cohort: cohortType, limit })
            .then((result) => {
                return result.data.map(d => {
                    return {
                        total: (d.month ?? 0) + (d.year ?? 0) + (d.semi_year ?? 0),
                        month: d.month ?? 0,
                        year: d.year ?? 0,
                        semiYear: d.semi_year ?? 0,
                        date: d.date.value.slice(5, 10),
                        dateTime: new Date(d.date.value).getTime(),
                        monthAdd: d.month_add,
                        yearAdd: d.year_add,
                        semiYearAdd: d.semi_year_add,
                        monthLost: -d.month_lost,
                        yearLost: -d.year_lost,
                        semiYearLost: -d.semi_year_lost,
                        totalAdd: (d.month_add ?? 0) + (d.year_add ?? 0) + (d.semi_year_add ?? 0),
                        totalLost: -((d.month_lost ?? 0) + (d.year_lost ?? 0) + (d.semi_year_lost ?? 0)),
                        totalChange: (d.month_add ?? 0) + (d.year_add ?? 0) + (d.semi_year_add ?? 0) + (-((d.month_lost ?? 0) + (d.year_lost ?? 0) + (d.semi_year_lost ?? 0)))
                    }
                })
            });
    }

    static getUsersRetention = async (cohortType, limit) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getUsersRetention');
        return functionCall({ cohort: cohortType, limit })
            .then((result) => {
                return result.data.map(d => {
                    return {
                        retention: d.retention,
                        change: d.change,
                        users_num: d.users_total,
                        users_remained: d.users_remained,
                        date: d.date.value.slice(5, 10),
                        dateTime: new Date(d.date.value).getTime()
                    }
                })
            })
    }

    static getUsersNums = async (cohortType, limit) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getUsersNums');
        return functionCall({ cohort: cohortType, limit })
            .then((result) => {
                return result.data.map(d => {
                    return {
                        num: d.num ?? 0,
                        old: d.old ?? 0,
                        new: d.num - d.old,
                        premium: d.premium ?? null,
                        date: d.date.value.slice(5, 10),
                        dateTime: new Date(d.date.value).getTime()
                    }
                })
            });
    }

    static getSubmissionsNums = async (cohortType, limit) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getSubmissionsNums');
        return functionCall({ cohort: cohortType, limit })
            .then((result) => {
                return result.data.map(d => {
                    return {
                        submissions: d.submissions ?? 0,
                        challenges: d.challenges ?? 0,
                        usersNum: d.users_num ?? 0,
                        date: d.date.value.slice(5, 10),
                        dateTime: new Date(d.date.value).getTime()
                    }
                })
            });
    }

    static getAIResponsesNums = async (cohortType, limit) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getAIResponsesNums');
        return functionCall({ cohort: cohortType, limit })
            .then((result) => {
                return result.data.map(d => {
                    return {
                        responses: d.responses ?? 0,
                        usersNum: d.users_num ?? 0,
                        date: d.date.value.slice(5, 10),
                        dateTime: new Date(d.date.value).getTime()
                    }
                })
            });
    }

    static getReferralNums = async (cohortType, limit) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getReferralNums');
        return functionCall({ cohort: cohortType, limit })
            .then((result) => {
                return result.data.map(d => {
                    return {
                        num: d.users ?? 0,
                        date: d.date.value.slice(5, 10),
                        dateTime: new Date(d.date.value).getTime()
                    }
                })
            });
    }

    static getSupportNums = async (cohortType, limit) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getSupportNums');
        return functionCall({ cohort: cohortType, limit })
            .then((result) => {
                return result.data.map(d => {
                    return {
                        num: d.num ?? 0,
                        date: d.date.value.slice(5, 10),
                        dateTime: new Date(d.date.value).getTime()
                    }
                })
            });
    }

    static getSubmissionsByUserFromContext = async (uid, context) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getSubmissionsByUserFromContext');
        return functionCall({ uid, context }).then(d => d.data)
    }

    static updateTag = async (id, data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateTag');
        return functionCall({ id, data })
    }

    static deleteTag = async (id) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'deleteTag');
        return functionCall({ id })
    }

    static updateTerm = async (id, data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateTerm');
        return functionCall({ id, data })
    }

    static deleteTerm = async (id) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'deleteTerm');
        return functionCall({ id })
    }

    static updateCourse = async (id, data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateCourse');
        return functionCall({ id, data })
    }

    static updateTerms = async (courseId, lessonId, terms) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateTerms');
        return functionCall({ courseId, lessonId, terms })
    }

    static getLessonTerms = async (courseId, lessonId) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getLessonTerms');
        return functionCall({ courseId, lessonId })
    }

    static updateCarousels = async (data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateCarousels');
        return functionCall({ data })
    }

    static getAskAiDataWithContext = async (uid, context) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getAskAiDataWithContext');
        return functionCall({ uid, context })
    }

    static getAskAiResponse = async (uid, id) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getAskAiResponse');
        return functionCall({ uid, id })
    }

    static getAIFeedbacks = async (fromTimestamp) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getAIFeedbacks');
        return functionCall({ fromTimestamp })
    }

    static updateBlog = async (data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateBlog');
        return functionCall({ data })
    }

    static publishBlog = async (data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'publishBlog');
        return functionCall({ data })
    }

    static updateJourney = async (data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateJourney');
        return functionCall({ data })
    }

    static publishJourney = async (data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'publishJourney');
        return functionCall({ data })
    }

    static updateFunnelOptions = async (id, data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateFunnelOptions');
        return functionCall({ id, data })
    }

    static getUserChallengesActivity = async (uid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getUserChallengesActivity');
        return functionCall({ uid })
    }

    static getCustomerData = async (cid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCustomerData');
        return functionCall({ cid })
    }

    static getCohortData = async (year, month, day, cohort) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCohortData');
        return functionCall({ year, month, day, cohort })
            .then(d => d.data)
    }

    static getCourseData = async (cohort, courseId) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCourseData');
        return functionCall({ courseId, cohort })
            .then(d => {
                return d.data.map(dd => {
                    return {
                        submissions: dd.submissions,
                        users: dd.users,
                        date: dd.timestamp.value.slice(5, 10),
                        dateTime: new Date(dd.timestamp.value).getTime(),
                    }
                });
            })
    }

    static setUserBillingData = async (uid, data) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'setUserBillingData');
        return functionCall({ uid, data })
            .then(d => d.data)
    }

    static getNewCustomersData = async (startTime, endTime) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getNewCustomersData', { timeout: 300000 });
        return functionCall({ startTime, endTime })
            .then(d => d.data)
            .catch(e => {
                console.log(e);
                return e;
            })
    }

    static updateCustomerFunnel = async (uid, funnel) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateCustomerFunnel');
        return functionCall({ uid, funnel })
    }

    static getCohortFunnel = async (year, month, day, cohort, end_range, custom_active = 0) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCohortFunnel');
        return functionCall({ year, month, day, cohort, end_range, custom_active })
            .then(d => {
                return d.data.map(dd => {
                    return {
                        activeUsers: dd.active_users ?? 0,
                        premiumUsers: dd.premium_users ?? 0,
                        premiumUsersActive: dd.premium_users_active ?? 0,
                        customActiveUsers: dd.custom_active_users ?? 0,
                        customPremiumUsersActive: dd.custom_premium_users_active ?? 0,
                        date: dd.date.value.slice(5, 10),
                        dateTime: new Date(dd.date.value).getTime(),
                        exposureActive: {
                            instagram: dd.active_users_socials_instagram ?? 0,
                            tiktok: dd.active_users_socials_tiktok ?? 0,
                            facebook: dd.active_users_socials_facebook ?? 0,
                            twitter: dd.active_users_socials_twitter ?? 0,
                            linkedin: dd.active_users_socials_linkedin ?? 0,
                            youtube: dd.active_users_socials_youtube ?? 0,
                            podcast: dd.active_users_socials_podcast ?? 0,
                            socials: dd.active_users_socials ?? 0,
                            referral: dd.active_users_referral ?? 0,
                            search_engine: dd.active_users_search_engine ?? 0,
                            google: dd.active_users_search_engine_google ?? 0,
                            bing: dd.active_users_search_engine_bing ?? 0,
                            other: dd.active_users_other ?? 0,
                            undefined: dd.active_users_undefined ?? 0,
                        },
                        exposurePremium: {
                            instagram: dd.premium_users_socials_instagram ?? 0,
                            tiktok: dd.premium_users_socials_tiktok ?? 0,
                            facebook: dd.premium_users_socials_facebook ?? 0,
                            twitter: dd.premium_users_socials_twitter ?? 0,
                            linkedin: dd.premium_users_socials_linkedin ?? 0,
                            youtube: dd.premium_users_socials_youtube ?? 0,
                            podcast: dd.premium_users_socials_podcast ?? 0,
                            socials: dd.premium_users_socials ?? 0,
                            referral: dd.premium_users_referral ?? 0,
                            search_engine: dd.premium_users_search_engine ?? 0,
                            google: dd.premium_users_search_engine_google ?? 0,
                            bing: dd.premium_users_search_engine_bing ?? 0,
                            other: dd.premium_users_other ?? 0,
                            undefined: dd.premium_users_undefined ?? 0,
                        }
                    }
                })
            })
    }

    static getLastGeneratedChallenges = async () => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getLastGeneratedChallenges');
        return functionCall({})
            .then(d => d.data)
    }

    static getChallengesData = async () => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getChallengesData');
        return functionCall({})
            .then(d => d.data)
    }

    static getCouponRevenue = async (promoCode, start, end) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCouponRevenue');
        return functionCall({ promoCode, start, end })
    }

    static getRevenueBreakdown = async (startTime, endTime) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getRevenueBreakdown', { timeout: 540000 });
        return functionCall({ startTime, endTime })
            .then(d => d.data)
    }

    static getFeedbacksNumbers = async () => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getFeedbacksNumbers');
        return functionCall({})
            .then(d => d.data)
    }

    static getCanceledSubscriptions = async () => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCanceledSubscriptions');
        return functionCall({})
            .then(d => d.data)
    }

    static sendSupportTicketMessage = async (tid, message, uid, noMail) => {
        const data = {
            sender: uid,
            body: message,
        }
        if (noMail) {
            data.noMail = noMail
        }
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'sendSupportTicketMessage');
        return functionCall({ tid, data })
    }

    static setSupportTicketStatus = async (tid, status) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'setSupportTicketStatus');
        return functionCall({ tid, status })
    }

    static getUserEvents = async (uid, year, month, day) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getUserEvents');
        return functionCall({ uid, year, month, day })
            .then(d => d.data)
    }

    static updateCreatorsPayments = async (data, id) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'updateCreatorsPayments');
        return functionCall({ data, id })
    }

    static getLastAIResponses = async () => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getLastAIResponses');
        return functionCall()
            .then(d => d.data)
    }

    static getCoddyUser = async (uid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCoddyUser');
        return functionCall({ uid })
            .then(d => d.data)
    }

    static isCoddyUserPremium = async (uid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'isCoddyUserPremium');
        return functionCall({ uid })
            .then(d => d.data)
    }

    static getCoddyUserFromShortenUid = async (shortenUid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCoddyUserFromShortenUid');
        return functionCall({ shortenUid })
            .then(d => d.data)
    }

    static getCoddyUserByEmail = async (email) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCoddyUserByEmail');
        return functionCall({ email })
            .then(d => d.data)
    }

    static getCoddyUsers = async (
        order,
        orderDirection,
        whereObject = null,
        whereObject2 = null,) => {
        console.log(order, orderDirection, whereObject, whereObject2);
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCoddyUsers');
        return functionCall({ order, orderDirection, whereObject, whereObject2 })
            .then(d => d.data)
    }

    static getCoddyUserProgress = async (uid, isChallenges = false) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCoddyUserProgress');
        return functionCall({ uid, isChallenges })
            .then(d => d.data)
    }

    static getCoddyUserCourseProgress = async (uid, isChallenges = false, cid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getCoddyUserCourseProgress');
        return functionCall({ uid, isChallenges, cid })
            .then(d => d.data)
    }

    static getUserActivityUsage = async (uid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getUserActivityUsage');
        return functionCall({ uid })
            .then(d => d.data)
    }

    static getUserWeeklyGoals = async (uid, week, year) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getUserWeeklyGoals');
        return functionCall({ uid, week, year })
            .then(d => d.data)
    }

    static getContactForms = async (lastTime = undefined) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getContactForms');
        return functionCall({ lastTime })
            .then(d => d.data)
    }

    static getLastInsights = async () => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getLastInsights');
        return functionCall()
            .then(d => d.data)
    }

    static getInsightsCount = async () => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getInsightsCount');
        return functionCall()
            .then(d => d.data)
    }

    static getInsightsByContext = async (courseId, lessonId) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getInsightsByContext');
        return functionCall({ courseId, lessonId })
            .then(d => d.data)
    }

    static getInsight = async (id) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getInsight');
        return functionCall({ id })
            .then(d => d.data)
    }

    static getFeedbacks = async (lastTime) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getFeedbacks');
        return functionCall({ lastTime })
            .then(d => d.data)
    }

    static getLeaderboards = async () => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getLeaderboards');
        return functionCall({})
            .then(d => d.data)
    }

    static getLeaderboardGroups = async (lid, lastTime) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getLeaderboardGroups');
        return functionCall({ lid, lastTime })
            .then(d => d.data)
    }

    static getLeaderboardGroupData = async (lid, gid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getLeaderboardGroupData');
        return functionCall({ lid, gid })
            .then(d => d.data)
    }

    static getLessonPreviewIds = async (cid, lid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'getLessonPreviewIds');
        return functionCall({ cid, lid })
            .then(d => d.data)
    }

    static deleteUser = async (uid) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'deleteUser');
        return functionCall({ uid })
            .then(d => d.data)
            .catch(e => {
                return { error: e }
            })
    }
    static getUserMetricsData = async (experimentId, groupId1, groupId2, startDate, endDate, isOnlyUs) => {
        const functions = getFunctions(getApp())
        const functionCall = httpsCallable(functions, 'userMetricsData');
        return functionCall({ experimentId, groupId1, groupId2, startDate, endDate, onlyUs: isOnlyUs })
            .then(d => d.data)
            .catch(e => {
                return { error: e }
            })
    }
}
